import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';

const fb = firebase
  .initializeApp({
    apiKey: 'AIzaSyCTKwRr5rsy_98hAGdsKvUaBqYulh6NeQA',
    authDomain: 'digital-intelligence-208511.firebaseapp.com',
    databaseURL: 'https://digital-intelligence-208511.firebaseio.com',
    projectId: 'digital-intelligence-208511',
    storageBucket: 'digital-intelligence-208511.appspot.com',
    messagingSenderId: '1038363698728',
    appId: '1:1038363698728:web:db210379b5a04d97',
  });

const db = fb.firestore();

const auth = fb.auth();

const functions = firebase.functions();

const { TimeStamp, GeoPoint } = firebase.firestore;

export {
  auth,
  db,
  functions,
  TimeStamp,
  GeoPoint,
};
