import Vue from 'vue';
import Router from 'vue-router';
import { auth, db } from './firestore';
import Page from './views/Page.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/account/login',
      name: 'account-login',
      component: () => import(/* webpackChunkName: "account" */ './views/Account/Login'),
      meta: {
        noAuthAllowed: true,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('./views/Dashboard'),
    },
    {
      path: '/',
      component: Page,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('./views/Page/Home'),
        },
        {
          path: 'db-schema/project/detail/:projectID',
          name: 'db-schema-project-detail',
          component: () => import(/* webpackChunkName: "db-schema" */ './views/Page/DbSchema/Project/Detail'),
        },
        {
          path: 'db-schema/project/list',
          name: 'db-schema-project-list',
          component: () => import(/* webpackChunkName: "db-schema" */ './views/Page/DbSchema/Project/List'),
        },
        {
          path: 'db-schema/project/migration/detail/:projectID/:migrationID',
          name: 'db-schema-project-migration-detail',
          component: () => import(/* webpackChunkName: "db-schema" */ './views/Page/DbSchema/Project/Migration/Detail'),
        },
        {
          path: 'db-schema/project/migration/upsert/:projectID/:migrationID?',
          name: 'db-schema-project-migration-upsert',
          component: () => import(/* webpackChunkName: "db-schema" */ './views/Page/DbSchema/Project/Migration/Upsert'),
        },
        {
          path: 'db-schema/project/deployment/upsert/:projectID/:type/:deploymentID?',
          name: 'db-schema-project-deployment-upsert',
          component: () => import(/* webpackChunkName: "db-schema" */ './views/Page/DbSchema/Project/Deployment/Upsert'),
        },
        {
          path: 'db-schema/project/upsert/:projectID?',
          name: 'db-schema-project-upsert',
          component: () => import(/* webpackChunkName: "db-schema" */ './views/Page/DbSchema/Project/Upsert'),
        },
        {
          path: 'deployer/detail/:deploymentID',
          name: 'deployer-detail',
          component: () => import(/* webpackChunkName: "deployer" */ './views/Page/Deployer/Detail'),
        },
        {
          path: 'deployer/list',
          name: 'deployer-list',
          component: () => import(/* webpackChunkName: "deployer" */ './views/Page/Deployer/List'),
        },
        {
          path: 'runner/job/detail/:jobID',
          name: 'runner-job-detail',
          component: () => import(/* webpackChunkName: "runner" */ './views/Page/Runner/Job/Detail'),
        },
        {
          path: 'runner/job/list',
          name: 'runner-job-list',
          component: () => import(/* webpackChunkName: "runner" */ './views/Page/Runner/Job/List'),
        },
        {
          path: 'runner/job/upsert/:jobID?',
          name: 'runner-job-upsert',
          component: () => import(/* webpackChunkName: "runner" */ './views/Page/Runner/Job/Upsert'),
        },
        {
          path: 'runner/job/schedule/upsert/:jobID/:scheduleID?',
          name: 'runner-job-schedule-upsert',
          component: () => import(/* webpackChunkName: "runner" */ './views/Page/Runner/Job/Schedule/Upsert'),
        },
        {
          path: 'service/port',
          name: 'service-port',
          component: () => import(/* webpackChunkName: "service" */ './views/Page/Service/Port'),
        },
        {
          path: 'welfare/project/detail/:projectID',
          name: 'welfare-project-detail',
          component: () => import(/* webpackChunkName: "welfare" */ './views/Page/Welfare/Project/Detail'),
        },
        {
          path: 'welfare/project/listener/:listenerID',
          name: 'welfare-project-listener',
          component: () => import(/* webpackChunkName: "welfare" */ './views/Page/Welfare/Project/Listener'),
        },
        {
          path: 'welfare/project/list',
          name: 'welfare-project-list',
          component: () => import(/* webpackChunkName: "welfare" */ './views/Page/Welfare/Project/List'),
        },
        {
          path: 'welfare/detail/team/:teamID',
          name: 'welfare-team-detail',
          component: () => import(/* webpackChunkName: "welfare" */ './views/Page/Welfare/Team/Detail'),
        },
        {
          path: 'welfare/team/list',
          name: 'welfare-team-list',
          component: () => import(/* webpackChunkName: "welfare" */ './views/Page/Welfare/Team/List'),
        },
        {
          path: 'code/list',
          name: 'code list',
          component: () => import(/* webpackChunkName: "welfare" */ './views/Page/Code/List'),
        },
        {
          path: 'code/shame',
          name: 'code list',
          component: () => import(/* webpackChunkName: "welfare" */ './views/Page/Code/Shame'),
        },
        {
          path: 'code/item/:itemId',
          name: 'code item',
          component: () => import(/* webpackChunkName: "welfare" */ './views/Page/Code/Item'),
        },
        {
          path: 'code/auth',
          name: 'code auth',
          component: () => import(/* webpackChunkName: "welfare" */ './views/Page/Code/Auth'),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  auth.onAuthStateChanged(async () => {
    if (to.matched.some(record => record.meta.noAuthAllowed) === true) next();
    else {
      const { currentUser } = auth;
      if (!currentUser) next({ name: 'account-login' });
      else {
        const { uid } = auth.currentUser;
        db.doc(`user-perm/${uid}`)
          .get()
          .then((result) => {
            const { shepherd } = result.exists ? result.data() : {};
            return shepherd === 'admin' ? next() : next({ name: 'account-login' });
          })
          .catch(() => next({ name: 'account-login' }));
      }
    }
  });
});

export default router;
