import Vue from 'vue';

const moment = require('moment');

const momentValue = (value) => {
  if (typeof value === 'object' && typeof value.seconds === 'number') {
    return new Date(value.seconds * 1000);
  }
  return value;
};

Vue.filter('moment', (value, format) => moment(momentValue(value))
  .format(format));

Vue.filter('momentFromNow', value => moment(momentValue(value)).fromNow());
