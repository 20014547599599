import Vue from 'vue';
import Vuex from 'vuex';
import { db } from '@/firestore';
Vue.use(Vuex);
import moment from 'moment';

export default new Vuex.Store({
  state: {
    mondayItems: [],
    repos: [],
    builds: JSON.parse(JSON.stringify({})),
    gh_token: ""
  },
  mutations: {
    SET_MONDAYITEMS (state, new_items) {
      state.mondayItems = new_items
    },
    SET_REPOS (state, new_items) {
      state.repos = new_items
    },
    SET_BUILDS (state, new_items) {
      state.builds = new_items
    },
    SET_TOKEN (state, gh_token) {
      localStorage.setItem('gh_token', gh_token);
      state.gh_token = gh_token
    },
  },
  actions: {
    async gh_setup({ commit }){
      const clientID = (await db.collection('code').doc('github').get()).data().clientID
      // setup the token if not already present
      const storedToken = localStorage.getItem('gh_token')
      if(!storedToken){
        localStorage.setItem('current_url_gh_call', window.location.href)
        try {
          location.assign(`https://github.com/login/oauth/authorize?client_id=${clientID}&scope=user%20repo`)
        } catch (error) {
          console.error(error)
        }
      }else{
        commit('SET_TOKEN', storedToken)
      }
    },

    async initialise({ commit }){
      const res =  await db.collection('code').doc('github').collection('reposNames').get()
      const data = res.docs.map(item=>item.data())
      commit('SET_REPOS', data)

      db.collection('code')
        .doc('monday')
        .collection('boardItems2')
        .onSnapshot(async snapBoardItem => {
          const mondayItems = await Promise.all(snapBoardItem.docs.map(async boardItem => {
            const item = boardItem.data();
            let filteredBranchesArray = []

            if(item.branch && item.branches){
              filteredBranchesArray = item.branches.map( branchItem => {
                  const newItem = { ...branchItem, repoName: branchItem.repoName || branchItem.repo };
                  const matchPR = item?.prStatus?.find(pr => (pr.repoName === branchItem.repo || pr.repoName === branchItem.repoName) && pr.name == branchItem.name) || {};
                  let matchBuild = item?.buildStatus?.filter(build => build.commitSha == matchPR.merge_commit_sha).sort(function (a, b) {
                    return moment.utc(a.finishTime).diff(moment.utc(b.finishTime))
                  }) || [{}]; // pick the last build
                  return { ...newItem, prStatus:matchPR, buildStatus: matchBuild[0]?.commitSha ? [matchBuild[0]]: [{}] };
                })
            }

            const newBranchName = item.branch?.length ? item.branch : item.name ? ( 'feature/' + item.name.replace(/[ ]{2,}/i, " ").replace(/[&\/\\#,+()$~%.'":*?<>{}^$@]/gi, "").split(' ').slice(0, 5).join('-')) + item.id.toString(): '';
            const newItem = { ...item };
            newItem['tags'] = item.tags;
            newItem['priority'] = item.priority;
            newItem['people'] = item.people ? item.people :'';
            newItem['status'] = item.status;
            newItem['branch'] = item.branch;
            newItem['prStatus'] = item.prStatus || [];
            newItem['filteredBranches'] = filteredBranchesArray
            newItem['id'] = item.id
            // a new branch name to be valid for github must not contain some special character and spaces
            newItem['branchName'] = newBranchName;
            return newItem;
          })) 

          commit('SET_MONDAYITEMS', mondayItems)
      })
    }
  },
  getters: {
    getMondayItems (state) {
      return state.mondayItems
    },
    getBuilds (state) {
      return state.builds
    },
    getToken (state) {
      return state.gh_token
    }
  }
});
